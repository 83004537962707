/*eslint-disable*/
import * as React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Images
// import Image from "@mui/material/Image";
import rodeNTG3ShotgunMicrophone from "assets/img/equipment-pics/Audio/Rode NTG-3 Shotgun Microphone.jpg";
import sennheiserMKHP48Microphone from "assets/img/equipment-pics/Audio/Sennheiser MKH 50 P48 Microphone.jpg";
import sennheiserMKH416ShortShotgunInterferenceTubeMicrophone from "assets/img/equipment-pics/Audio/Sennheiser MKH-416 Short Shotgun Interference Tube Microphone.jpg";
import shureVP64AOmnidirectionalHandheldMicrophone from "assets/img/equipment-pics/Audio/Shure VP64A Omnidirectional Handheld Microphone.jpg";
import zoomF6MultiTrackFieldRecorderKTekKSF6StingrayBag from "assets/img/equipment-pics/Audio/Zoom F6 Multi-Track Field Recorder With K-Tek KSF6 Stingray Bag .jpg";

// core components

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Collapse from "@mui/material/Collapse";
import { ListItemText } from "@material-ui/core";
import { Grid, ListItemButton } from "@mui/material";

const useStyles = makeStyles(styles);
const textStyle = { color: "#3C4858" };
const imgHeight = "100px";
const IMAGES = {
  "Rode NTG-3 Shotgun Microphone": rodeNTG3ShotgunMicrophone,
  "Sennheiser MKH 50 P48 Microphone": sennheiserMKHP48Microphone,
  "Sennheiser MKH-416 Short Shotgun Interference Tube Microphone": sennheiserMKH416ShortShotgunInterferenceTubeMicrophone,
  "Shure VP64A Omnidirectional Handheld Microphone": shureVP64AOmnidirectionalHandheldMicrophone,
  "Zoom F6 Multi-Track Field Recorder with K-Tek KSF6 Stingray Bag": zoomF6MultiTrackFieldRecorderKTekKSF6StingrayBag
};

export default function AudioEquipment(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleEquipmentClick = () => {
    setOpen(!open);
  };
  const EQUIPMENTLIST = ({ items }) =>
    Object.entries(items).map(([k, v]) => (
      <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
        <ListItemText
          primary={k}
          style={textStyle}
          className={classes.description}
        />
        <img src={v} height={imgHeight} />
      </Grid>
    ));
  return (
    <List>
      <ListItemButton onClick={handleEquipmentClick}  style={{ backgroundColor: "#3c85b3" }} >
        <ListItemText
          primary="Audio Equipment"
          style={{ color: "#FFFFFF", borderBottom: 1, borderColor: "primary" }}
          className={classes.description}
        />
        {open ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <EQUIPMENTLIST items={IMAGES} />
        </Grid>
      </Collapse>
    </List>
  );
}
