/*eslint-disable*/
import * as React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Images
// import Image from "@mui/material/Image";
import roundPaperLanterns12Inch from "assets/img/equipment-pics/ElectricLighting/12 Inch White Round Paper Lanterns (10 Pack).jpg";
import amaran300cLEDMonolight from "assets/img/equipment-pics/ElectricLighting/amaran 300c RGB LED Monolight.jpg";
import aputure60xLightStormLS60 from "assets/img/equipment-pics/ElectricLighting/Aputure 60x Aputure Light Storm LS 60x Bi-Color LED Light.jpg";
import aputureAccentB7CSmartLEDLight from "assets/img/equipment-pics/ElectricLighting/Aputure Accent B7C Smart Led Light Bulbs 7W RGBWW.jpg";
import aputureAmaranLightDomeMiniSoftBox from "assets/img/equipment-pics/ElectricLighting/Aputure amaran Light Dome Mini SE Softbox.jpg";
import aputureAmaranP60cLEDPanelVideoLight from "assets/img/equipment-pics/ElectricLighting/Aputure amaran P60c LED Panel Video Light RGBWW 60w.jpg";
import aputureAmaranP60xVideoPanelLight from "assets/img/equipment-pics/ElectricLighting/Aputure Amaran P60X Video Panel Light _ Color Temperature 3200K-6500K.jpg";
import aputureLanternSoftbox from "assets/img/equipment-pics/ElectricLighting/Aputure Lantern Softbox (2.2_).jpg";
import aputureLS1200dProLEDLightKit from "assets/img/equipment-pics/ElectricLighting/Aputure LS 1200d Pro LED Light Kit with Fresnel.jpg";
import aputureMTProLightWand from "assets/img/equipment-pics/ElectricLighting/Aputure MT Pro RGB Light Wand _ 7.5W Output _ Bi-Color 2000K -10000KPixel-Mappable RGBWW.jpg";
import aputureNovaP300cLEDLightPanel from "assets/img/equipment-pics/ElectricLighting/Aputure Nova P300c RGB LED Light Panel with softbox and case.jpg";
import DigitalCinema12AWGACExtensionCord from "assets/img/equipment-pics/ElectricLighting/Digital Cinema 12 AWG AC Extension Cord.jpg";
import ecoflowRiverProPortablePowerStation from "assets/img/equipment-pics/ElectricLighting/ECOFLOW RIVER Pro Portable Power Station 720Wh.jpg";
import kinofloDivaLight401 from "assets/img/equipment-pics/ElectricLighting/Kinoflo Diva Light 401.jpg";
import nanLitePavoTube30C4 from "assets/img/equipment-pics/ElectricLighting/NanLite PavoTube 30C 4_ RGBW LED Tube 2-Light Kit, 32W, 2700K-6500K.jpg";

// core components

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Collapse from "@mui/material/Collapse";
import { ListItemText } from "@material-ui/core";
import { Grid, ListItemButton } from "@mui/material";

const useStyles = makeStyles(styles);
const textStyle = { color: "#3C4858" };
const imgHeight = "100px";
const IMAGES = {
  "12\" White Round Paper Lanterns (10 Pack)": roundPaperLanterns12Inch,
  "Amaran 300c RGB LED Monolight": amaran300cLEDMonolight,
  "Aputure 60x Aputure Light Storm LS 60x Bi-Color LED Light": aputure60xLightStormLS60,
  "Aputure Accent B7C Smart LED Light Bulbs 7W RGBWW": aputureAccentB7CSmartLEDLight,
  "Aputure Amaran Light Dome Mini SE Softbox": aputureAmaranLightDomeMiniSoftBox,
  "Aputure Amaran P60c LED Panel Video Light RGBWW 60W": aputureAmaranP60cLEDPanelVideoLight,
  "Aputure Amaran P60X Video Panel Light: 3200K-6500K": aputureAmaranP60xVideoPanelLight,
  "Aputure Lantern Softbox": aputureLanternSoftbox,
  "Aputure LS 1200d Pro LED Light Kit with Fresnel": aputureLS1200dProLEDLightKit,
  "Aputure MT Pro RGB Light Wand: 7.5W Bi-Color 2000K-10000K": aputureMTProLightWand,
  "Aputure Nova P300c RGB LED Light Panel with Softbox and Case": aputureNovaP300cLEDLightPanel,
  "Digital Cinema 12 AWG AC Extension Cord": DigitalCinema12AWGACExtensionCord,
  "ECOFLOW River Pro Portable Power Station 720Wh": ecoflowRiverProPortablePowerStation,
  "Kinoflo Diva Light 401": kinofloDivaLight401,
  "NanLite PavoTube 30C LED Tube Light: 32W 2700K-6500K": nanLitePavoTube30C4
};

export default function ElectricLightingEquipment(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleEquipmentClick = () => {
    setOpen(!open);
  };
  const EQUIPMENTLIST = ({ items }) =>
    Object.entries(items).map(([k, v]) => (
      <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
        <ListItemText
          primary={k}
          style={textStyle}
          className={classes.description}
        />
        <img src={v} height={imgHeight} />
      </Grid>
    ));
  return (
    <List>
      <ListItemButton onClick={handleEquipmentClick}  style={{ backgroundColor: "#3c85b3" }} >
        <ListItemText
          primary="Lighting Equipment"
          style={{ color: "#FFFFFF", borderBottom: 1, borderColor: "primary" }}
          className={classes.description}
        />
        {open ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <EQUIPMENTLIST items={IMAGES} />
        </Grid>
      </Collapse>
    </List>
  );
}
