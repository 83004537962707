/*eslint-disable*/
import * as React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Images
// import Image from "@mui/material/Image";
import blankFiringPistol9mm from "assets/img/equipment-pics/PropsCostume/9mm Blank Firing Pistol.jpg";
import airPistolRevolver from "assets/img/equipment-pics/PropsCostume/Air Pistol Revolver .177 Cal_4.5mm CO2 BB Gun.jpg";
import crosmanFullAutoP1 from "assets/img/equipment-pics/PropsCostume/Crosman CFAMP1L Full Auto P1 CO2-Powered BB Air Pistol With Laser Sight.jpg";
import fullAutoSBR from "assets/img/equipment-pics/PropsCostume/Full Auto SBR CO2-Powered BB Air Rifle with Dual Action Capability.jpg";
import nyThreadsProfessionalLabCoat from "assets/img/equipment-pics/PropsCostume/NY Threads Professional Lab Coat, Full Sleeve Poly Cotton Long Medical Coat.jpg";
import paramedStethoscope from "assets/img/equipment-pics/PropsCostume/Paramed Stethoscope.jpg";
import unisexScrubsBlackMed from "assets/img/equipment-pics/PropsCostume/Unisex Scrubs Black Medium.jpg";
import unisexScrubsMaroonMed from "assets/img/equipment-pics/PropsCostume/Unisex Scrubs Maroon Medium.jpg";

// core components

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Collapse from "@mui/material/Collapse";
import { ListItemText } from "@material-ui/core";
import { Grid, ListItemButton } from "@mui/material";

const useStyles = makeStyles(styles);
const textStyle = { color: "#3C4858" };
const imgHeight = "100px";
const IMAGES = {
  "9mm Blank Firing Pistol": blankFiringPistol9mm,
  "Air Pistol Revolver .177 CO2 BB Gun": airPistolRevolver,
  "Crosman CFAMP1L Full Auto P1 CO2 BB Air Pistol with Laser Sight": crosmanFullAutoP1,
  "Full Auto SBR CO2 BB Air Rifle with Dual Action Capability": fullAutoSBR,
  "NY Threads Professional Lab Coat, Full Sleeve": nyThreadsProfessionalLabCoat,
  "Paramed Stethoscope": paramedStethoscope,
  "Unisex Scrubs Black Medium": unisexScrubsBlackMed,
  "Unisex Scrubs Maroon Medium": unisexScrubsMaroonMed
};

export default function PropsCostumesEquipment(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleEquipmentClick = () => {
    setOpen(!open);
  };
  const EQUIPMENTLIST = ({ items }) =>
    Object.entries(items).map(([k, v]) => (
      <Grid item xs={12} sm={6} md={4} lg={3} textAlign="center">
        <ListItemText
          primary={k}
          style={textStyle}
          className={classes.description}
        />
        <img src={v} height={imgHeight} />
      </Grid>
    ));
  return (
    <List>
      <ListItemButton onClick={handleEquipmentClick}  style={{ backgroundColor: "#3c85b3" }} >
        <ListItemText
          primary="Props and Costumes"
          style={{ color: "#FFFFFF", borderBottom: 1, borderColor: "primary" }}
          className={classes.description}
        />
        {open ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <EQUIPMENTLIST items={IMAGES} />
        </Grid>
      </Collapse>
    </List>
  );
}
